import React from "react"
import Footer from "../components/footer"

import SEO from "../components/seo"
import ogImageAGB from "../images/og-image/og-image-agb.png"
export default function Agb() {
  return (
    <>
      <SEO
        title="AGB - Cuuno"
        description="Hier finden Sie alle Information bezüglich unserer Allgemeinen Geschäftsbedinungen. Die auf dieser Seite folgenden Ausführungen sind Vertragsbestandteil
        einer Vereinbarung mit Cuuno."
        image={ogImageAGB}
        defaultTitle="AGB - Cuuno"
        titleTemplate="AGB"
      />
      <div className="cb-container">
       
            <div class="cb-header -info">
              <h1>
                Allgemeine Geschäftsbedingungen 
              </h1>
            </div>
         
        <div class="cb-text">
          <p>
            Allgemeine Geschäftsbedingungen von Cuuno. <br />
            Die auf dieser Seite folgenden Ausführungen sind Vertragsbestandteil
            einer Vereinbarung mit Cuuno. Ein Vertrag zwischen Auftraggeber und
            Cuuno kommt mit der Annahme des jeweiligen Angebots bzw. mit einer
            Auftragsbestätigung zustande, siehe hierzu auch 1.4. <br />
            <br />
            1. Honorar <br />
            1.1 Grundlage der Honorarberechnung ist unser jeweiliges Angebot
            über die beauftragten Leistungen. Alle Leistungen des Angebots
            verstehen sich zzgl. der zum Zeitpunkt der Rechnungsstellung
            gültigen gesetzlichen Mehrwertsteuersätze. Das Angebot unterliegt
            einer Dauer von 1 Monat und verliert nach dieser Zeit seine
            Gültigkeit, sofern in der Zwischenzeit kein Auftrag erteilt wurde
            oder eine andere Gültigkeitsdauer festgelegt wird. Vom Angebot
            abweichende mündliche Absprachen sind ohne schriftliche Fixierung
            ungültig. Eine Überschreitung von Abgabeterminen berechtigt den
            Auftraggeber nicht zum Rücktritt von seinem Auftrag. Unsere Angebote
            sind stets freibleibend und zwingen nicht zur Auftragsannahme zu den
            dort genannten Konditionen. Zusätzlich erbrachte Leistungen, die
            über den Leistungsumfang des jeweiligen Angebotes hinausgehen,
            werden gesondert in Rechnung gestellt. Dies betrifft insbesondere
            mögliche nachträgliche Änderungen des Auftraggebers, sogenannte
            Autorenkorrekturen.
            <br /> <br />
            1.2 In den Honoraren von Cuuno, nachfolgend Auftragnehmer genannt,
            sind technische Kosten wie die Erstellung von Grafiken,
            Illustrationen, Fotoarbeiten, Videoarbeiten, Bildnutzungsrechte,
            Belichtung von Dateien, Texterfassung, Musterproduktion, Druck wie
            auch ggf. Schaltkosten von Anzeigen nicht enthalten. Sie werden
            gesondert in Rechnung gestellt. Alle Fahrtkosten für Reisen und
            sonstige Nebenauslagen, die bei der Abwicklung des Auftrages
            entstehen, werden ebenfalls gesondert berechnet. Datenträger wie
            auch Daten bleiben unser Eigentum. Mit der Zahlung von Honoraren
            erwirbt der Auftraggeber keinerlei Rechte an Daten wie auch an
            Datenträgern.
            <br />
            <br />
            1.3 Für die Entwicklung konzeptioneller und gestalterischer
            Vorschläge zur Vorbereitung eines Vertragsabschlusses berechnen wir
            ein Präsentationshonorar, das im Falle einer Auftragserteilung auf
            das Gesamthonorar angerechnet werden kann, sofern dies im Angebot
            vermerkt wurde. Die Rechte der von uns im Rahmen der Präsentation
            vorgelegten Arbeiten und Entwürfe verbleiben stets bei uns und gehen
            erst nach der Erteilung eines Auftrages zur Ausarbeitung und nach
            Erstattung eines Nutzungshonorares, das objekt- und auflagenabhängig
            ist, zeitweilig an den Auftraggeber über. Die gezeigten Unterlagen
            verbleiben im Besitz des Auftragnehmers und müssen nach Prüfung,
            spätestens jedoch nach Ablauf von zwei Wochen nach Präsentation frei
            an den Auftragnehmer zurückgesandt werden. Im Falle der Einbehaltung
            von Präsentationsunterlagen hat der Auftragnehmer das Recht, dem
            Auftraggeber zur Wiedererstellung dieser Unterlagen ein zusätzliches
            Honorar in Rechnung zu stellen. Es gilt als vereinbart, daß die Höhe
            dieses zusätzlichen Honorares sich auf die Höhe des ursprünglichen
            Präsentationshonorares plus gesetzlicher Mehrwertsteuer beläuft.
            Näheres zu Urheberrecht und Nutzungsrechten in den
            Honorarempfehlungen des BDG, Bund deutscher Grafiker.
            <br />
            <br />
            1.4 Ein Auftrag gilt als erteilt, wenn der Auftragsbestätigung des
            Auftragnehmers nicht innerhalb von 5 Werktagen widersprochen wird
            oder auf mündlichen Wunsch des Auftraggebers Vorschläge erarbeitet
            wurden, die dem Auftraggeber vorliegen. Sofern nichts anderes bei
            Auftragserteilung vereinbart wurde, wird dem Auftraggeber jeweils
            mindestens ein gestalterischer oder konzeptioneller Vorschlag
            vorgelegt, der auch bei eventueller Nichtakzeptanz auf Seiten des
            Auftraggebers den durch die Auftragserteilung geschlossenen Vertrag
            erfüllt und voll in Rechnung gestellt wird. Eine Zahlung von
            verminderten, sogenannten „Ausfallhonoraren”, wird hiermit von
            Seiten des Auftraggebers ausdrücklich ausgeschlossen. <br />
            <br />
            2. Unsere Pflichten
            <br />
            <br /> 2.1 Wir verpflichten uns, die uns übertragenen Aufgaben mit
            fachlicher und kaufmännischer Sorgfalt nach bestem Wissen und
            Gewissen und unter Beachtung der Wünsche unserer Auftraggeber
            durchzuführen.
            <br />
            <br />
            2.2 Aufträge an dritte Unternehmen und Personen erteilen wir nach
            Absprache mit unseren Auftraggebern auf Rechnung und Namen des
            Auftraggebers, sofern diese Absprache getroffen wurde. Sofern der
            Auftraggeber sich ein Mitspracherecht nicht ausdrücklich vorbehalten
            hat, erfolgt die Beauftragung Dritter unter der Beachtung eines
            ausgewogenen Verhältnisses von Wirtschaftlichkeit und bestmöglichem
            Erfolg für unseren Auftraggeber.
            <br />
            <br /> 2.3 Besprechungsergebnisse bedürfen zu Ihrer Wirksamkeit
            einer schriftlichen Fixierung durch einen Besprechungsbericht. Wird
            diesem nicht innerhalb von 5 Werktagen widersprochen, so gilt im
            Interesse einer zügigen Auftragsabwicklung das dort Festgehaltene.
            <br />
            <br />
            3. Nutzungsrechte Die Parteien vereinbaren, daß auf alle von Cuuno,
            erstellten Arbeiten und Leistungen die Bestimmungen des
            Urheberrechtsgesetzes Anwendung finden und zwar auch dann, wenn die
            Voraussetzungen für einen urheberrechtlichen Schutz nach § 2 UrhRG
            (z.B. Schöpfungshöhe) nicht vorliegen. Der Auftraggeber erwirbt erst
            nach vollständiger Bezahlung des Honorars ein einfaches
            Nutzungsrecht, um die Arbeiten und Leistungen von Cuuno, in dem
            vertraglich vereinbarten Umfang, zu den vertraglich vereinbarten
            Zwecken und für die Laufzeit dieses Vertrages bzw. für eine
            ausdrücklich vereinbarte Dauer und räumlich auf das Gebiet der
            Bundesrepublik Deutschland bzw. gemäß dem Vertragszweck beschränkt,
            zu verwenden. Eine weitergehende Nutzung bedarf der ausdrücklichen,
            schriftlichen Einwilligung durch Cuuno. Ebenso bedarf jede
            Bearbeitung oder sonstige Veränderung und jede nicht vertragsgemäße
            Nutzung der ausdrücklichen schriftlichen Einwilligung durch Cuuno.
            Der Auftraggeber verpflichtet sich, stets Cuuno, als Urheber der
            Arbeiten und Leistungen an geeigneter Stelle wie folgt zu benennen:
            „Copyright by www.cuuno.com“ und diese Domain zu verlinken.
            Sämtliche Rechte an im Rahmen einer Präsentation gezeigten Arbeiten
            verbleiben bei Cuuno. Mit der Zahlung des Präsentationshonorars wird
            lediglich der Präsentationsaufwand erstattet; der Auftraggeber
            erwirbt mit dieser Zahlung keinerlei Nutzungsrechte oder sonstige
            über die Präsentation hinausgehenden Ansprüche. Wir nehmen darüber
            hinaus für alle Arbeiten und Leistungen den Schutz durch das Gesetz
            gegen den unlauteren Wettbewerb, das Geschmacksmustergesetz, das
            Markengesetz und das Patentgesetz in Anspruch, soweit diese
            anwendbar sind.
            <br />
            <br />
            4. Haftung
            <br />
            <br />
            4.1 Wir haften nur für Vorsatz und nachgewiesene grobe
            Fahrlässigkeit, die von einem amtlich bestellten Sachverständigen
            festgestellt werden muß. Zu unseren Aufgaben gehört es auch, den
            Auftraggeber auf erkennbare Bedenken gegen geplante Aufträge
            gegenüber Dritten hinzuweisen. Eine etwaige notwendige rechtliche
            Prüfung der Form, Inhalte und Aussagen ist Aufgabe des
            Auftraggebers. Gerne lassen wir dies auf Ihre Anfrage hin von
            unseren Anwälten prüfen.
            <br />
            <br />
            4.2 Eine Haftung für etwaige Folgeschäden, die sich aus dem Auftrag
            oder seiner Abwicklung ergeben, ist ausdrücklich ausgeschlossen.
            <br />
            <br />
            4.3 Für fremdes Verschulden, insbesondere bei Zulieferern,
            Fremdprodukten und Insertionen, wie auch für höhere Gewalt ist jede
            Haftung ausgeschlossen.
            <br />
            <br />
            4.4 Alle von uns erstellten oder überarbeiteten Texte und Layouts
            sind vor Satzerstellung und Drucklegung vom Auftraggeber zu prüfen
            und abzuzeichnen. Unterläßt der Auftraggeber diese Prüfung, so kann
            für die entstandenen Kosten keine Haftung übernommen werden. Eine
            Haftung für Schäden, die durch Textaussagen hervorgerufen werden
            könnten, ist ausgeschlossen. Sollten von uns erbrachte Leistungen
            teilweise oder ganz fehlerhaft sein, so behalten wir uns das Recht
            der Nachbesserung an unseren Arbeiten vor.
            <br />
            <br />
            5. Zahlungsbedingungen
            <br />
            <br />
            5.1 Bei Überschreitung der Zahlungsfrist von 14 Tagen rein netto
            kommt der Auftraggeber ohne Mahnung in Verzug. Vorbehaltlich
            sonstiger Rechte darf der Auftragnehmer Verzugszinsen in Höhe von 2%
            über dem jeweils gültigen Dispositionskredit-Zinssatzes der Hausbank
            von Cuuno, in Rechnung stellen. Die durch das Überschreiten der
            Zahlungsfrist entstehenden Mahn-, Anwalts- und Gerichtskosten, wie
            auch damit verbundene Nebenauslagen trägt allein der Auftraggeber.
            <br />
            <br />
            5.2 Bei Aufträgen sind Abschlagszahlungen wie folgt zu leisten
            (sofern nichts Anderes schriftlich vereinbart wurde): 50% der
            Auftragssumme sind Teil der Beauftragung und mit der schriftlichen
            Bestätigung des Angebots anzuweisen. Der Restbetrag von 50% der
            Auftragssumme ist nach Abnahme durch den Kunden und vor Übergabe des
            fertiggestellten Auftrages an den Kunden zu leisten.
            <br />
            <br />
            5.3 Ist der Auftraggeber mit einer fälligen Zahlung in Verzug oder
            tritt in seinem Vermögen eine wesentliche Verschlechterung ein, so
            behalten wir uns das Recht vor, für noch ausstehende Zahlungen unter
            Fortfall des Zahlungszieles bare Zahlung vor oder ab Auslieferung
            unserer Leistungen oder derer von zuliefernden Betrieben zu
            verlangen.
            <br />
            <br />
            5.4 Die Abrechnung mit bestrittenen Gegenforderungen und die
            Zurückhaltung fälliger Rechnungsbeträge ist unzulässig.
            <br />
            <br />
            6. Schlussbestimmungen
            <br />
            <br />
            6.1 Die Unwirksamkeit einzelner Bedingungen berührt die Wirksamkeit
            der anderen Bestimmungen nicht.
            <br />
            <br />
            6.2 Anwendbar ist ausschließlich das Recht der Bundesrepublik
            Deutschland.
            <br />
            <br />
            6.3 Gerichtsstand für alle Streitigkeiten zwischen dem Auftraggeber
            und dem Auftragnehmer ist Aulendorf.
            <br />
            <br />
            6.4 Mit Erteilung eines Auftrages an den Auftragnehmer erkennt der
            Auftraggeber die allgemeinen Geschäftsbedingungen des Auftragnehmers
            als Grundlage der Beziehungen zwischen Auftraggeber und
            Auftragnehmer an. Diese Bestimmungen gelten mit Erteilung eines
            Auftrages als vertraglich vereinbart.
            <br />
            <br />
            Aulendorf, 01.06.2020
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}
